<template>
  <div>
    <NavBar
    style="max-height: 50px;margin-bottom: 10px;"
    :username="currentUserName"
    :pageName="pageName"
    ></NavBar>

    <v-app>
      <div style="background-color: #f5faff; height: 93vh !important;">
        <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
        </v-col>

          <v-col cols="10">
             <div style="margin: 10px 20px 20px 50px ">
              <div
              style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              vertical-align: middle;
              "
              >
            <!-- <h3 style="text-align:left; margin-left:65px; margin-top:30px; font-weight:800; font-size: 12pt;
                text-transform: uppercase;   letter-spacing: 1px;">Add Doctor</h3> -->
                 <div style="width: 30%" align="left">
                 <span style="font-weight: 800; padding-left: 8px;  font-size: 12pt;
                  text-transform: uppercase;   letter-spacing: 1px;">
                  <h3>Add Doctor</h3>
                 </span>
                 </div>
                </div>
            <v-card style="width:90%; margin-left:2px; height:100%; margin-top:20px;	height: 70vh; overflow:scroll; overflow-x: hidden;">
              <div style="display: flex; flex-direction: row; margin:10px" align="left">
                <div
                style="width: 100%; display: flex; flex-direction: column"
                align="left">

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Doctor Join Date:<span style="color:red">*</span></p></div>
                  <div style="width: 50%;">
                    <v-menu
                      ref="menu_doj"
                      v-model="menu_doj"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="doctor_doj"
                          label="Date of Joining"
                          background-color="#F4F6FA"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          style="border-radius:8px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker style="padding:20px; height: 500px;color: black"
                        v-model="doctor_doj"
                        show-adjacent-months
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row;">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; margin-bottom: 12px; font-size:10pt; font-weight:600">Profile Picture:</p></div>
                  <div style="width: 50%" align="right">
                    <input type = "file" @change="getBase64" />
                  </div>
                </div>

                <div style="display: flex; flex-direction: row;">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; font-size:10pt; font-weight:600"> Doctor Full Name in English:<span style="color:red">*</span></p></div>
                  <div style="width: 50%" align="right">
                    <v-text-field
                    label="Enter Name in English"
                    solo
                    v-model="doctor_name"
                    :rules="docNameRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction:row;">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; font-size:10pt; font-weight:600"> Doctor Ful Name in Burmese:<span style="color:red">*</span></p></div>
                  <div style="width: 50%" align="right">
                    <v-text-field
                    label="Enter Name in Burmese"
                    solo
                    v-model="doctor_name_mm"
                    :rules = "docNameRules"
                    ></v-text-field>
                  </div>
                </div>



                <div style="display: flex; flex-direction: row;">
                  <div style="width: 50%" align="left"><p style="margin-top:12px; font-size:10pt; font-weight:600"> Gender:<span style="color:red">*</span></p></div>
                  <div style="width: 50%" align="right">
                    <v-select
                    label="Enter the gender"
                    :items = "gender"
                    solo
                    v-model="doctor_gender"
                    :rules = "genderRules"
                    ></v-select>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600"> Date of Birth:<span style="color:red">*</span></p></div>
                  <div style="width: 50%;">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="doctor_dob"
                          label="Date of Birth"
                          background-color="#F4F6FA"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          style="border-radius:8px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker style="padding:20px; height: 500px;color: black"
                        v-model="doctor_dob"
                        show-adjacent-months
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%" align="left">
                    <p  style="margin-top:12px;font-size:10pt; font-weight:600">Contact Phone Number:<span style="color:red">*</span></p>
                  </div>
                  <div style="width: 50%" align="right">
                    <v-text-field
                    type="number"
                    class="hideArrows"
                    solo
                    label="Enter Phone Number"
                    v-model="phone_number"
                    :rules="mobileNumberRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Alternate Phone number:</p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Enter Alternate Number"
                    solo
                    type="number"
                    class="hideArrows"
                    v-model="altNumber"
                    :rules="altMobileNumberRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Email:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Enter Email"
                    solo
                    v-model="doctor_email"
                    :rules="emailRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600"> Residential State:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Residential State"
                    solo
                    v-model="resState"
                    :rules = "stateRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row;">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; font-size:10pt; font-weight:600"> SAMA No:<span style="color:red">*</span></p></div>
                  <div style="width: 50%" align="right">
                    <v-text-field
                    label="Enter Doctor SAMA ID"
                    solo
                    v-model="doctor_id"
                    :rules="doctorIdRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Academic Title:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-select
                    label="Select Title"
                    :items = "academicTitles"
                    solo
                    v-model="docTitle"
                    :rules = "titleRules"
                    ></v-select>
                    <!-- <v-text-field
                    label="e.g Medical Officer"
                    solo
                    v-model="docTitle"
                    :rules = "titleRules"
                    ></v-text-field> -->
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Experience Years:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Experience"
                    solo
                    type="number"
                    class="hideArrows"
                    v-model="experience"
                    :rules="experienceRules"
                    ></v-text-field>
                  </div>
                </div>

                <!-- <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Service Year</p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Service Year"
                    solo
                    type="number"
                    class="hideArrows"
                    v-model="service_year"
                    ></v-text-field>
                  </div>
                </div> -->

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Relevant Medical Degree:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-textarea
                    label="Enter degrees"
                    solo
                    v-model="short_description"
                    :rules = "descRules"
                    ></v-textarea>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Speciality:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-select
                    label="Specialization"
                    :items = "specialist_array"
                    item-text = "specialization_name"
                    item-value = "_id"
                    solo
                    return-object
                    v-model="selected_specialization"
                    :rules="specRules"
                    ></v-select>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Specialized subjects:</p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Specialized subjects"
                    solo
                    v-model="specSubs"
                    :rules="specSubsRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Affiliated Hospital:</p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Affiliated Hospital"
                    solo
                    v-model="affHos"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Commited Schedule:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Commited Schedule"
                    solo
                    v-model="docSchedule"
                    :rules="docScheduleRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Consultation Minutes:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Consultation Minutes"
                    solo
                    v-model="docMeetMins"
                    :rules="meetingMinutesRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Break time minutes before teleconsulting:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Break Time minutes"
                    solo
                    v-model="docBreakMins"
                    :rules = "breakMinutesRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Service Fee:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Service Fee"
                    solo
                    type="number"
                    class="hideArrows"
                    v-model="service_fee"
                    :rules="serviceFeeRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Commission:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Commission Fee"
                    solo
                    type="number"
                    class="hideArrows"
                    v-model="commission"
                    :rules="commissionFeeRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">KBZ Pay Number:<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="KBZPay Number"
                    solo
                    type="number"
                    class="hideArrows"
                    v-model="kbzPayNumber"
                    :rules = "kbzpayRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">KBZ Bank Account Number:</p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Account Number"
                    solo
                    type="number"
                    class="hideArrows"
                    v-model="account_number"
                    :rules="accountNumberRules"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row;">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; font-size:10pt; font-weight:600">Payment Settlement:<span style="color:red">*</span></p></div>
                  <div style="width: 50%" align="right">
                    <v-select
                    label="Payment mode"
                    :items = "mode_of_settlement_list"
                    solo
                    v-model="selected_payment"
                    ></v-select>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Suggested Medicines to Keep in stock:</p></div>
                  <div style="width: 50%">
                    <v-text-field
                    label="Suggested Medicines"
                    solo
                    v-model="docMeds"
                    ></v-text-field>
                  </div>
                </div>

                <!-- <div style="display: flex; flex-direction: row">
                  <div style="width: 50%"><p  style="margin-top:12px;font-size:10pt; font-weight:600">Short Description (Burmese)<span style="color:red">*</span></p></div>
                  <div style="width: 50%">
                    <v-textarea
                    label="Enter description in Burmese"
                    solo
                    v-model="short_description_mm"
                    :rules = "descRules"
                    ></v-textarea>
                  </div>
                </div> -->

                <div align="center">
                  <v-btn
                  @click="createDoctor"
                  style=" width:50%; height: 40px; margin-right:10%; margin-top:20px;background-color: #1467BF;"
                  color="primary"
                  :disabled = "checkValidButton()"
                  >Create Doctor</v-btn>
                </div>
              </div>
            </div>
          </v-card>
       </div> </v-col>
      </v-row>
    </div>
  </v-app>
</div>
</template>


<script>
import axios from "axios";
import NavBar from "../../navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: "CustomerHome",
  data() {
    return {
      doctor_name: '',
      doctor_name_mm : "",
      doctor_email: "",
      currentUser: "",
      currentUserName: "",
      user_id: "",
      phone_number: "",
      pageName: "createDoctor",
      specialist_array: [],
      selected_specialization: "",
      short_description: "",
      short_description_mm : "",
      doctor_id: "",
      gender: ["Male", "Female","Other"],
      academicTitles : ["Professor and Head" , "Professor" , "Associate Professor" , "Senior Consultant" , "Consultant" , "Specialist" , "GP"],
      menu: "",
      menu_doj: "",
      doctor_dob: "",
      doctor_doj: "",
      doctor_gender: "",
      //service_year: "",
      account_number: "",
      kbzPayNumber: "",
      acc_number_max: 14,
      service_fee: "",
      commission: "",
      profile_pic: "",
      experience: "",
      resState: "",
      altNumber: "",
      docTitle: "",
      specSubs: "",
      affHos: "",
      docSchedule: "",
      docMeetMins: "",
      docBreakMins: "",
      docMeds: "",
      mode_of_settlement_list: ["Monthly", "Bi-weekly"],
      selected_payment: "",
      experienceRules : [v => !!v || 'Experience is required',
      v => v > 0 || "Experience cannot be negative"],
      serviceFeeRules : [v => !!v || 'Service is required',
      v => v > 0 || "Service Fee cannot be negative"],
      commissionFeeRules : [v => !!v || 'Commission is required',
      v => v > 0 || "Commission Fee cannot be negative"],
      //serviceYearRules : [v => v > 0 || "Service Year cannot be negative"],
      mobileNumberRules: [
      v => !!v || 'Phone Number is required',
      v => (v.length > 6 && v.length < 12) || 'Phone Number should be between 7 to 11 digits',
      v => v > 0 || 'Phone Number cannot be negative',
      ],
      altMobileNumberRules: [
      v => (v.length > 6 && v.length < 12) || 'Phone Number should be between 7 to 11 digits',
      v => v > 0 || 'Phone Number cannot be negative',
      ],
      accountNumberRules: [
      v => (v && v.length > 0 ) || 'Account Number should be 17 digits',
      v => v > 0 || 'Account number cannot be negative',
      ],
      specSubsRules : [
                        v => (!v || (v.length > 0 && (/^[^,]+(?:,[^,]*){0,2}$/g.test(v)))) || 'Enter 3 specialized Subjects at most'
                      ],
      docNameRules: [ v => !!v || 'Name is required'],
      genderRules: [ v => !!v || 'Gender is required'],
      emailRules: [ 
                    v => !!v || 'Email is required',
                    v => (/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/g.test(v)) || 'Enter valid Email'
                    ],
      doctorIdRules: [ v => !!v || 'SAMA ID is required'],
      stateRules: [ v => !!v || 'Residential State is required'],
      specRules: [ v => !!v || 'Specialization is required'],
      titleRules: [ v => !!v || 'Title is required'],
      meetingMinutesRules: [ v => !!v || 'Consultation Time is required'],
      breakMinutesRules: [ v => !!v || 'Break Duration is required'],
      docScheduleRules: [ v => !!v || 'Doctor Commited Schedule is required'],
      kbzpayRules: [ v => !!v || 'KBZPay account number is required'],
      descRules: [ v => !!v || 'Description is required'],
      settlementRules: [ v => !!v || 'Settlement is required'],
      isCreateDoctorClicked : false
    };
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN"
      };
      axios_auth_instance_admin.post("/adminCreateDoctorPage", data)
      .then((successResponse) => {
        this.user_id = successResponse.data.admin_data._id;
        this.specialist_array = successResponse.data.data;
      })
      .catch((errorResponse) => {
        handleError(errorResponse, this.$router, 'adminCreateDoctorPage', 'AdminLogin', 'token');
      });
    }
  },
  components: {
    NavBar,SideBar
  },
  methods: {
    checkValidButton()
    {
      return this.isCreateDoctorClicked || !(this.doctor_name.trim() != '' && this.doctor_name_mm.trim() != '' && this.doctor_email.trim() != '' && this.doctor_id.trim() != '' && this.doctor_id.trim().length >= 4 && this.selected_specialization && this.service_fee > 0 && this.commission > 0 && this.phone_number && this.phone_number.length >= 7 && this.phone_number.length <= 11  && this.experience && this.experience > 0 && this.selected_payment && this.doctor_gender && this.doctor_dob && this.doctor_doj && this.resState.trim() != '' && this.short_description.trim() != ''&& this.docTitle.trim() != '' && this.kbzPayNumber && this.docMeetMins.trim() != '' && this.docBreakMins.trim() != '' && this.docSchedule.trim() != '' && (!this.altNumber || (this.altNumber.length == 0 || this.altNumber > 0))
       && (!this.altNumber || this.altNumber.length == 0 || (this.altNumber.length >= 7 && this.altNumber.length <= 11)) && (!this.specSubs || (this.specSubs.length > 0 && (/^[^,]+(?:,[^,]*){0,2}$/g.test(this.specSubs)))))
    },
    createDoctor() {
      this.isCreateDoctorClicked = true;
      var createDoctorBody = {
        doctorName: this.doctor_name.trim(),
        doctorName_mm : this.doctor_name_mm.trim() != '' ? this.doctor_name_mm.trim() : this.doctor_name,
        doctorID: this.doctor_id,
        specialist: [{"_id": this.selected_specialization._id,"specialization_name": this.selected_specialization.specialization_name}],
        doctorPhonenumber: this.phone_number[0] == 0 ? this.phone_number : "0" + this.phone_number,
        doctorEmail: this.doctor_email.trim(),
        service_fee: this.service_fee,
        commission: this.commission,
        experience: this.experience,
        gender: this.doctor_gender,
        description: this.short_description.trim(),
        description_mm : this.short_description.trim(),
        kbzPayNumber: this.kbzPayNumber,
        kbzBankAccountNumber: this.account_number,
        //serviceYear: this.service_year,
        base64ProfilePic: this.profile_pic,
        modeOfSettlement : this.selected_payment,
        state: this.resState.trim(),
        altNumber: this.altNumber,
        title: this.docTitle.trim(),
        specSubs: this.specSubs,
        hospital: this.affHos,
        schedule: this.docSchedule.trim(),
        meetingMinutes: this.docMeetMins.trim(),
        breakMinutes: this.docBreakMins.trim(),
        suggestedMeds: this.docMeds,
        createdBy: this.user_id,
        token: this.currentUser,
        joinDate: this.doctor_doj,
        dateOfBirth: this.doctor_dob,
        typeOfUser: "ADMIN",
      };
      axios
      .post(
        process.env.VUE_APP_BACKEND_URL + "/createDoctor",
        createDoctorBody
        )
      .then((createDoctorResponse) => {
        this.isCreateDoctorClicked = false
        alert("Doctor created!!");
        this.$router.push({
          name: 'ViewAllDoctors'
        });
      })
      .catch((createDoctorError) => {
        this.isCreateDoctorClicked = false
        if (createDoctorError.response.status == 401) {
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name: "AdminLogin",
          });
        }
        else if(createDoctorError.response.status == 403) {
          window.alert("Please fill all required details");
        }
        else if (createDoctorError.response.status == 305) {
          window.alert("Doctor already exists");
        }
      });
    },
    getBase64(e){
      // get the files
     let files = e.target.files;
      // Process each file
      var allFiles = [];
      let file = files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000)+' kB',
          base64: reader.result,
          file: file
        };
        this.profile_pic = fileInfo.base64;
      }
    }
  },
};
</script>

<style>
td,
th {
  padding: 10px;
}
#facebook{
  display: none;
}
</style>

<style>

  .hideArrows input::-webkit-outer-spin-button,
  .hideArrows input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  /* Firefox */
  .hideArrows input[type=number] {
    -moz-appearance: textfield !important;
  }
</style>